import NodeCache from 'node-cache';

import { fetchJSON } from '@bonnet/next/fetch';

import { cpoContentDuck } from '@/ducks';

// cache to prevent multiple fetches of cpo content
const cpoCache = new NodeCache({
    stdTTL: 3600,
});

export default function withCPOcontent(configOption = {}) {
    return async (ctx) => {
        const options = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            circuitBreaker: {
                id: 'cpo_certifiedcontentsimple',
                timeout: 3000,
                resetTimeout: 30000,
                fallback: {},
            },
            ...configOption,
        };
        try {
            let certifiedContentSimple = cpoCache.get('certifiedContentSimple');
            if (!certifiedContentSimple) {
                let contentPrefix = '/content/static';
                if (typeof window === 'undefined') {
                    contentPrefix = process.env.CONTENT_BUCKET;
                }
                certifiedContentSimple = await fetchJSON(contentPrefix + '/json/atc/cpo/certifiedcontentsimple.json', options);
                cpoCache.set('certifiedContentSimple', certifiedContentSimple);
            }
            ctx.store.dispatch(cpoContentDuck.creators.assign({ certifiedContentSimple }));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error while fetching cpo content: ', error);
        }
    };
}
