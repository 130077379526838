import { createAction, createSelector, Duck, DuckSelector } from '@atc/modular-redux';

const defaultState = {
    isLoggedIn: false,
};

const selectors = () => ({
    isLoggedIn: new DuckSelector((selector) => createSelector(
        selector.getDuckState,
        (duckState) => duckState.isLoggedIn
    )),
});

const reducer = (state, action, { initialState, types }) => {
    switch (action.type) {
        case types.SET_LOGGED_IN: {
            return {
                ...state,
                ...defaultState,
                isLoggedIn: true,
            };
        }

        case types.SET_LOGGED_OUT: {
            return {
                ...state,
                ...defaultState,
            };
        }
        default:
            return state;
    }
};

const creators = (duck) => ({
    setLoggedIn: createAction(duck.types.SET_LOGGED_IN),
    setLoggedOut: createAction(duck.types.SET_LOGGED_OUT),
});

const authDuckCreator = ({
    namespace = '',
    store = 'auth',
    storePath,
} = {}) => new Duck({
    namespace,
    store,
    storePath,
    initialState: {
        ...defaultState,
    },
    consts: {},
    types: [
        'SET_LOGGED_IN',
        'SET_LOGGED_OUT',
    ],
    creators: (duck) => creators(duck),
    reducer,
    selectors,
});

export default authDuckCreator();
