export function getOwnerWebsite(website = {}, params = {}) {
    if (!website?.href) {
        return website;
    }

    // Use the URL API, but fail gracefully if URL is malformed
    let url;
    try {
        url = new URL(website.href);
    } catch {
        return website;
    }

    // Config features may contain duplicate keys (snake_case and camelCase)
    // So explicitly defining parameters we want to support for href manipulation
    // in a single place
    const supportedParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
    ];

    // Temporarily strip ATC-added parameters
    // for backward compatibility
    if (['autotrader.com', 'kbb.com'].includes(url.searchParams.get('utm_source'))
        && ['autotrader_listings', 'kbb_listings'].includes(url.searchParams.get('utm_campaign'))
    ) {
        url.searchParams.delete('utm_source');
        url.searchParams.delete('utm_campaign');
        url.searchParams.delete('utm_medium');
    }
    // End temp code

    // Set parameters and values
    supportedParams.forEach((param) => {
        if (!!params[param] && !url.searchParams.has(param)) {
            url.searchParams.append(param, params[param]);
        }
    });

    return {
        ...website,
        href: url.toString(),
    };
}

export default getOwnerWebsite;
