import { useEffect, useRef, useState } from 'react';

import { useStore } from 'react-redux';

import { useRouter } from 'next/router';

import {
    srpNewSearchDuck,
} from '@/ducks/srp';

export const saveScrollPos = (key, selector) => {
    let scrollPos = { x: window.scrollX, y: window.scrollY };

    if (selector) {
        const element = document.querySelector(selector);
        scrollPos = { x: element.scrollLeft, y: element.scrollTop };
    }

    sessionStorage.setItem(`scrollPos:${key}`, JSON.stringify(scrollPos));
};

export const restoreScrollPos = (key, selector) => {
    const json = sessionStorage.getItem(`scrollPos:${key}`);
    const scrollPos = json ? JSON.parse(json) : { x: 0, y: 0 };

    if (selector) {
        const element = document.querySelector(selector);
        setTimeout(() => {
            element.scrollTo({
                left: scrollPos.x,
                top: scrollPos.y,
                behavior: 'smooth',
            });
        }, 100);
    } else {
        window.scrollTo({
            left: scrollPos.x,
            top: scrollPos.y,
            behavior: 'smooth',
        });
    }
};

export const deleteScrollPos = (key) => {
    sessionStorage.removeItem(`scrollPos:${key}`);
};

export function ScrollRestoration({ router = null, enabled = true, selector = null, delay = null } = {}) {
    const nextRouter = useRouter();
    const prevRoutePath = useRef(null);

    if (!router) {
        router = nextRouter;
    }

    const [key, setKey] = useState(null);

    useEffect(() => {
        setKey(router.asPath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const store = useStore();
    const state = store.getState();
    const isNewSearch = srpNewSearchDuck.selectors.getDuckState(state);

    useEffect(() => {
        if (!enabled) return () => {};

        const onBeforeUnload = () => {
            deleteScrollPos(key);
            deleteScrollPos(router.asPath);
        };

        const onRouteChangeStart = () => {
            prevRoutePath.current = router.asPath;
            saveScrollPos(key, selector);
        };

        const onRouteChangeComplete = () => {
            setKey(router.asPath);

            if (delay != null) {
                setTimeout(() => {
                    if ((prevRoutePath.current !== router.asPath) && !isNewSearch) restoreScrollPos(router.asPath, selector);
                    deleteScrollPos(router.asPath);
                }, delay);
            } else {
                if ((prevRoutePath.current !== router.asPath) && !isNewSearch) restoreScrollPos(router.asPath, selector);
                deleteScrollPos(router.asPath);
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, [delay, enabled, key, router.asPath, router.events, selector, isNewSearch]);
}

export default ScrollRestoration;
