import { OptimizelyDecideOption } from '@optimizely/optimizely-sdk';

import { getOptimizelyInstance } from 'reaxl-optimizely';

// convert string from snake_case to camelCase
const snakeToCamel = (str = '') => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

// duplicates snakecase keyed properties with camelCase keyed properties
const duplicatePropertiesWithCamelCase = (acc, [key, value]) => {
    acc[key] = value;

    if (key.includes('-') || key.includes('_')) {
        acc[snakeToCamel(key)] = value;
    }

    return acc;
};

// adds useFeatures functionality to the ctx object
export default function withUseFeatures() {
    return async (ctx) => {

        const { featuresConfig = {}, optimizelyDynamicConfig = {} } = ctx.data;
        const { features } = featuresConfig;
        const optimizely = getOptimizelyInstance();
        const { featuresMap: optimizelyFeatures = {} } = optimizely.getOptimizelyConfig();
        const { user = {}, forcedFeatures: forcedOptimizelyFeatures = {} } = optimizelyDynamicConfig;
        const userInstance = optimizely.createUserContext(user.id, user.attributes);

        // looks up singular feature key
        ctx.useFeature = (key) => {
            // default feature to NOT enabled
            let isFeatureEnabled = false;
            let featureVariables = {};

            try {
                if (forcedOptimizelyFeatures[key]) {
                    isFeatureEnabled = true;
                    featureVariables = {
                        ...optimizely.getAllFeatureVariables(key, user.id, user.attributes),
                        ...forcedOptimizelyFeatures[key],
                    };
                } else if (optimizelyFeatures[key]) {
                    const { enabled, variables = {} } = userInstance.decide(key, typeof window === 'undefined' ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT] : null);
                    isFeatureEnabled = enabled;
                    featureVariables = variables;
                } else if (features[key]) {
                    const { enabled: configEnabled, variables: configVariables = {} } = features[key];
                    isFeatureEnabled = configEnabled;
                    featureVariables = configVariables;
                }

                // duplicate snake_case property keys
                featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

                return [isFeatureEnabled, featureVariables];
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('ERROR: withUseFeatures - ', error);
            }

            // if Optimizely errors out, build useFeatures with just brand config data
            if (features[key]) {
                const { enabled: configEnabled, variables: configVariables = {} } = features[key];
                isFeatureEnabled = configEnabled;
                featureVariables = configVariables;
            }

            // duplicate snake_case property keys
            featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

            // return with just brand config data if optimizely throws an error
            return [isFeatureEnabled, featureVariables];
        };

        ctx.useFeatures = (keys = []) => {
            const featuresData = [].concat(keys).reduce((acc, key) => ({
                ...acc,
                [key]: ctx.useFeature(key),
            }), {});

            // duplicate snake_case property keys
            return Object.entries(featuresData).reduce(duplicatePropertiesWithCamelCase, {});
        };
    };
}
