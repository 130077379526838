import { createSelector } from '@bonnet/next/redux';

import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const consumerRatingsDuck = objectDuckCreator({
    store: 'consumerRatings',
    initialState: {},
}).extend({
    selectors: {
        getConsumerRatings: new DuckSelector((selectors) => (state) => selectors.getDuckState(state)),
        hasConsumerRatings: new DuckSelector((selector) => createSelector(
            selector.getDuckState,
            (consumerRatings) => Object.keys(consumerRatings).length !== 0 && consumerRatings?.numberOfReviews !== 0
        )),
    },
});

export default consumerRatingsDuck;
