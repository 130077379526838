import { additionalResourcesFromListing } from '@/utilities';

import { createSelector, DuckSelector } from '@atc/modular-redux';

import { hydrateInventory, inventoryDuckCreator } from 'axl-ducks';

// TODO: Why are we still using the babel import rewrite.  It always assumes that exports are back by files

// These imports must be direct imports and not destructured from the barrel index file

import brandDuck from './brandDuck';
import ownersDuck from './ownersDuck';

const inventoryDuck = inventoryDuckCreator().extend({
    creators: (duck) => ({
        addVdpInventory: (listing = {}, iskbb) => {
            let data = {};
            if (listing.id) {
                data = {
                    accelerate: listing.accelerate,
                    additionalInfo: listing.additionalInfo || {},
                    atTrim: listing.atTrim,
                    autoFiDealerCode: listing.autoFiDealerCode,
                    bodyStyleCodes: listing.bodyStyleCodes || [],
                    certifiedProduct: listing.certifiedProduct || [],
                    certifiedTiles: listing.certifiedTiles || [],
                    classSeries: listing.classSeries || '',
                    classSeriesCode: listing.classSeriesCode || '',
                    clickType: listing.clickType,
                    color: listing.color || {},
                    consumerId: listing.consumerId || '',
                    creditApp: listing.creditApp,
                    daysOnSite: listing.daysOnSite || '',
                    dealerConfig: listing.dealerConfig || {},
                    defaultInterestRate: listing.defaultInterestRate || '',
                    displacementUOM: listing.displacementUOM || 0,
                    driveGroup: listing.driveGroup || '',
                    driveType: listing.driveType || {},
                    ecommWebsite: listing.ecommWebsite || {},
                    electricComponentInfo: listing.electricComponentInfo || {},
                    electricVehicleRange: listing.electricVehicleRange || 0,
                    engine: listing.engine || {},
                    esntial: listing.esntial,
                    exteriorColorSimple: listing.exteriorColorSimple || listing.color?.exteriorColorSimple || '',
                    externalOwnerId: listing.externalOwnerId || '',
                    fullDescription: listing.fullDescription || '',
                    hasLeatherSeats: listing.hasLeatherSeats || false,
                    interiorColorSimple: listing.interiorColorSimple || listing.color?.interiorColorSimple || '',
                    features: listing.features || false,
                    finance: listing.finance || [],
                    financingTypePSX: listing.financingTypePSX || '',
                    fordSalesCode: listing.fordSalesCode || '',
                    fuelType: listing.fuelType || '',
                    fuelTypeGroup: listing.fuelTypeGroup || listing.fuelType?.group || '',
                    fyuseId: listing.fyuseId,
                    hasDealerDiff: listing.hasDealerDiff || false,
                    hasDealerDiffUseDeepLink: listing.hasDealerDiffUseDeepLink || false,
                    hasPlaylist: listing.hasPlaylist || false,
                    hasSpecialOffer: listing.hasSpecialOffer || false,
                    hasVehicleExchange: listing.hasVehicleExchange || false,
                    id: listing.id,
                    images: listing.images || {},
                    inspectionLink: listing.inspectionLink,
                    insurancePartnerEnabled: listing.insurancePartnerEnabled || false,
                    isConquestable: listing.similarInventoryConquest || false,
                    isHot: listing.isHot || false,
                    fyusion: listing.fyusion || false,
                    isNewlyListed: listing.isNewlyListed || false,
                    isNoHagglePrice: listing.noHagglePrice || false,
                    isLongHaulDelivery: listing.longHaulDelivery || listing?.marketExtension?.longHaulDelivery || false,
                    isReducedPrice: listing.isReducedPrice || false,
                    isRoadsterDigitalRetailing: listing.roadster || false,
                    isCarNowDigitalRetailing: listing.carNow || false,
                    isWestlakePrequal: listing.westlakePrequal || false,
                    kbbConsumerRatings: listing.kbbConsumerRatings || 0,
                    kbbConsumerReviewCount: listing.kbbConsumerReviewCount || 0,
                    kbbVehicleId: listing.kbbVehicleId || '',
                    leadScheduling: listing.leadScheduling || false,
                    leaseLengths: listing.leaseLengths || '',
                    lifestyleCategories: listing.lifestyleCategories,
                    listingTitle: listing.pageData?.listingTitle || listing?.title,
                    listingTitleLong: listing.pageData?.listingTitleLong || listing?.titleLong,
                    listingTitlePSX: listing.listingTitlePSX || '',
                    listingType: listing.type || listing.listingType,
                    mainImageIsStock: listing.mainImageIsStock || false,
                    make: listing.make || '',
                    makeCode: listing.makeCode || '',
                    marketExtension: listing.marketExtension || {},
                    marketExtDescription: listing.marketExtDescription || '',
                    mileage: listing.mileage || {},
                    model: listing.model || '',
                    modelCode: listing.modelCode || '',
                    moneyBackGuarantee: listing.moneyBackGuarantee || false,
                    mpgCity: listing.mpgCity || '',
                    mpgHighway: listing.mpgHighway || '',
                    owner: listing.ownerId || '',
                    ownerName: listing?.owner?.name,
                    packages: listing.packages || [],
                    paymentServices: listing.paymentServices || {},
                    premiumSpotlight: listing.premiumSpotlight || false,
                    priceAdvisor: listing.priceAdvisor || {},
                    priceAdvisorEnabled: listing.priceAdvisorEnabled || false,
                    priceAdvisorRuleEnabled: listing.priceAdvisorRuleEnabled || false,
                    pricingDetail: listing.pricingDetail || {},
                    pricingHistory: listing.pricingHistory || [],
                    priority: listing.priority || {},
                    productTiles: listing.productTiles,
                    specifications: listing.specifications || {},
                    safeGuardProgram: listing.safeGuardProgram,
                    safetyRecall: listing.safetyRecall,
                    // TODO: Remove stockNumber lookup when 100% migrated to LSC
                    stockNumber: listing.stockId || listing.stockNumber || '',
                    styleId: listing.styleId || '',
                    resources: listing.resources || additionalResourcesFromListing(listing.owner, iskbb),
                    returnPolicy: listing.returnPolicy || {},
                    transmission: listing.transmission || {},
                    trim: listing.trim || '',
                    trimCode: listing.trimCode || '',
                    truckBedLength: listing.truckBedLength || '',
                    truckBedSizeDescription: listing.truckBedSizeDescription || '',
                    type: listing.type || listing?.pageData?.listingType || listing.listingType || '',
                    vhrPreview: listing.vhrPreview,
                    vhrTiles: listing.vhrTiles,
                    videoId: listing.videoId,
                    videos: listing.videos || [],
                    vin: listing.vin || '',
                    vinSightData: listing.vinSightData,
                    warranties: listing.warranties || {},
                    website: listing.website || { href: listing.reqUrl }, // website object is not available when you directly land on vdp
                    windowSticker: listing.windowSticker || {},
                    year: listing.year || '',
                    zip: listing.zip || '',
                };
            }
            return {
                type: duck.types.ADD_INVENTORY,
                payload: {
                    [listing?.id]: data,
                },
            };
        },
    }),
    selectors: () => ({
        selectHydratedInventory: new DuckSelector((selectors) => createSelector(
            selectors.getInventory,
            ownersDuck.selectors.getOwners,
            brandDuck.selectors.getDuckState,
            (inventory, owners, brand) => Object.keys(inventory).reduce((acc, id) => ({
                ...acc,
                [id]: hydrateInventory(id, inventory, owners, brand),
            }), {}),
        )),
    }),
});

export default inventoryDuck;
