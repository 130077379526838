import { useEffect } from 'react';

import { useCookies } from 'react-cookie';

const COOKIE_NAME = 'caiSuppressGoogleOneTap';

/*
 * https://ghe.coxautoinc.com/Consumer/auth-utility/wiki#suppress-google-one-tap-prompt
 * Listen for a custom event from auth-utility to see if google one tap prompt has rendered.
 * If one tap prompt has rendered once, and another action outside the prompt happens,
 * add the auth-utility cookie to suppress it for a set amount of time.
 * If suppression cookie already exists, do not check for prompt.
 */
export function OneTapSignInSuppression() {
    const [cookies, setCookie] = useCookies([COOKIE_NAME]);
    const includesOneTapCookie = cookies && cookies[COOKIE_NAME];

    useEffect(() => {
        function onGoogleOneTapInit() {
            if (!includesOneTapCookie) {
                const options = {
                    maxAge: 86400, // 24 hours
                    path: '/',
                };

                setCookie(COOKIE_NAME, true, options);
            }
        }

        window.caiEventBus.on('auth.googleonetapcomplete', onGoogleOneTapInit);

        return () => {
            window.caiEventBus.off('auth.googleonetapcomplete');
        };
    }, [includesOneTapCookie, setCookie]);
}

export default OneTapSignInSuppression;
