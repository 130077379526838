import { joinSplitSeoUrl } from '@atc/join-split-seo-url';

export default {
    rootDomain: joinSplitSeoUrl({
        host: ['fordblueadvantage', 'com'],
    }),
    wwwUrl: joinSplitSeoUrl({
        host: ['www', 'fordblueadvantage', 'com'],
    }),
    openGraphImage: joinSplitSeoUrl({
        protocol: 'https://',
        host: ['www', 'fordblueadvantage', 'com'],
        pathname: ['/content/static/img/partner/ford/open-graph_ford.png'],
    }),
    consumerPrivacyPage: joinSplitSeoUrl({
        protocol: 'https://',
        host: ['www', 'fordblueadvantage', 'com'],
        pathname: ['/cars-for-sale/fordblueadvantage/consumer-privacy'],
    }),
    prod: {
        rootUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['www', 'fordblueadvantage', 'com'],
        }),
        baseUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['www', 'fordblueadvantage', 'com'],
            pathname: ['/'],
        }),
        inventoryImageUrl: joinSplitSeoUrl({
            host: ['img', 'fordblueadvantage', 'com'],
        }),
        landingPage: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['www', 'fordblueadvantage', 'com'],
            pathname: ['/cars-for-sale'],
        }),
        landingPageOGImage: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['www', 'fordblueadvantage', 'com'],
            pathname: ['/content/static/img/fba/landing/open-graph_ford.webp'],
        }),
        landingPageOGUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['www', 'fordblueadvantage', 'com'],
            pathname: ['/cars-for-sale', '/'],
        }),
    },
    nonProd: {
        rootUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['test', 'fordblueadvantage', 'com'],
        }),
        baseUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['test', 'fordblueadvantage', 'com'],
            pathname: ['/'],
        }),
        inventoryImageUrl: joinSplitSeoUrl({
            host: ['images-test', 'fordblueadvantage', 'com'],
        }),
        landingPage: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['test', 'fordblueadvantage', 'com'],
            pathname: ['/cars-for-sale'],
        }),
        landingPageOGImage: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['test', 'fordblueadvantage', 'com'],
            pathname: ['/content/static/img/fba/landing/open-graph_ford.webp'],
        }),
        landingPageOGUrl: joinSplitSeoUrl({
            protocol: 'https://',
            host: ['test', 'fordblueadvantage', 'com'],
            pathname: ['/cars-for-sale', '/'],
        }),
    },
};
