import _get from 'lodash/get';

import { objectDuckCreator } from '@atc/modular-redux';

import { brands } from 'reaxl-brand';

const globalConfigsDuck = objectDuckCreator({
    store: 'configs',
    initialState: {},
}).extend({
    selectors: {
        isKbb: (state) => _get(state, 'brand', 'atc') === brands.KBB_BRAND,
        getAdsEnabled: (state) => _get(state, 'configs.adsEnabled'),
        getAemHost: (state) => _get(state, 'configs.aemHost', ''),
        getAutoFiPreQualUrl: (state) => _get(state, 'configs.autoFiPreQualUrl'),
        getFordMotorCreditPreQualUrl: (state) => _get(state, 'configs.fordMotorCreditPreQualUrl'),
        getFordMotorCreditPreQualSource: (state) => _get(state, 'configs.fordMotorCreditPreQualSource'),
        getCcpaEndpoint: (state) => _get(state, 'configs.ccpaEndpoint'),
        getCcpaRecordId: (state) => _get(state, 'configs.ccpaRecordId'),
        getCreditAppHost: (state) => _get(state, 'configs.creditAppHost'),
        getOpenWrapUrl: (state) => _get(state, 'configs.openWrapUrl'),
        getOpenWrapGlobalUrl: (state) => _get(state, 'configs.openWrapGlobalUrl'),
        getPriceAdvisorApiKey: (state) => _get(state, 'configs.priceAdvisorApiKey'),
        getPriceAdvisorApiUrl: (state) => _get(state, 'configs.priceAdvisorApiUrl'),
        getWestlakePreQualUrl: (state) => _get(state, 'configs.westlakePreQualUrl'),
        getCarNowHostUrl: (state) => _get(state, 'configs.carNowHostUrl'),
        getCarNowSlideoutUrl: (state) => _get(state, 'configs.carNowUrl'),
    },
});

export default globalConfigsDuck;
