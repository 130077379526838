import { pageNames } from '@atc/bonnet-paths';

const { SEARCH_RESULTS, VEHICLE_DETAILS, SEARCH_FORM, FORD_LANDING, BUILD_PRICE, INVENTORY_DETAILS } = pageNames;

export const asfDuckPreset = {
    namespace: SEARCH_FORM,
    storePrefix: `${SEARCH_FORM}_`,
};

export const flpDuckPreset = {
    namespace: FORD_LANDING,
    storePrefix: `${FORD_LANDING}_`,
};

export const srpDuckPreset = {
    namespace: SEARCH_RESULTS,
    storePrefix: `${SEARCH_RESULTS}_`,
};

export const vdpDuckPreset = {
    namespace: VEHICLE_DETAILS,
    storePrefix: `${VEHICLE_DETAILS}_`,
};

export const bapDuckPreset = {
    namespace: BUILD_PRICE,
    storePrefix: `${BUILD_PRICE}_`,
};

export const invDuckPreset = {
    namespace: INVENTORY_DETAILS,
    storePrefix: `${INVENTORY_DETAILS}_`,
};
